<template>
    <div class="user-detail">
        <Topbar :title="formData && `${formData.name}_详细信息`" :hasBack="true" @back="back">
            <template slot="menu">
                <Menu :menuList="menuList" v-model="pageType"></Menu>
            </template>
            <template slot="right" v-if="hasAction">
                <div class="topbar-btn-group">
					 <button class="btn submit-btn" @click="handleCreate('EDIT')" v-permission="['/hrms/user/update']">
                        <i class="iconfont icon-bianji2"></i>
                        <span>编辑</span>
                    </button>
                    <button class="btn submit-btn" @click="handleCreate('CHANGE')" v-permission="['/hrms/user/change/change-create']">
                        <i class="iconfont icon-bianji1"></i>
                        <span>变更</span>
                    </button>
                    <button class="btn submit-btn" @click="handleCreate('ADJUST_POST')" v-permission="['/hrms/user/change/leave-create']">
                        <i class="iconfont icon-diaozhi-copy"></i>
                        <span>调职</span>
                    </button>
                    <button class="btn submit-btn" @click="handleCreate('LEAVE')" v-permission="['/hrms/user/change/leave-create']">
                        <i class="iconfont icon-litui-copy"></i>
                        <span>离退</span>
                    </button>
                </div>
            </template>
        </Topbar>

        <div class="body" v-if="formData">
            <div class="content" >
                <BaseInfo v-if="pageType === 'baseInfo'" :formData="formData" :isDetail="true">
                </BaseInfo>
                <Annex v-if="pageType === 'annex'" :fileList="formData.fileInfoList" :userId="formData.id"></Annex>
                <History v-if="pageType === 'history'" :userId="userId"></History>
            </div>
            
            <div class="btn-group">
                <button class="btn submit-btn" @click="addContrast">
                    <i class="iconfont icon-tianjia"></i>
                    <span>加入对比</span>
                </button>
                <button class="btn submit-btn" @click="openContrastClick">
                    <i class="iconfont icon-jinru-copy"></i>
                    <span>进入对比</span>
                    <span class="prompt" v-if="contrastUserIds.length">{{contrastUserIds.length}}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Topbar from '@/components/topbar';
import { userDetail , updateFlowUser, createFlowUser , createJobChangeUser , getFormData } from '@/api/hrAPI.js';
import { getContrastList , setContrastList } from '@/api/loginAPI.js';
import BaseInfo from './baseInfo';
import Annex from './annex';
import History from './history';
import Detail from '@/components/detail';
import TransferForm from '@/components/form/TransferForm.vue';
import LeaveForm from '@/components/form/LeaveForm.vue';
import { education } from "@/components/table/data";

export default {
    components: {
        Topbar,
        BaseInfo,
        Annex,
        History,
        Detail,
        TransferForm,
        LeaveForm,
    },
    inject: ['updateForm'],
    data(){
        return {
            pageType: '',
            formData: null,             //  当前用户信息
            createType: '',
            detailInfoForm: null,         //  修改的表单信息,重提功能
            changeForm: {
                adjustReason: '',
                adjustTime: '',
                approvalAuthorityOpinion: '',
                currentOrgId: '',
                currentPositionIds: '',
                formType: '',
                idCard: '',
                notes: '',
                originalOrgId: '',
                originalPositionIds: '',
                positionChangeOpinion: '',
                reportUnit: '',
                type: '',
                name: '',
            },
            userList: [],
			contrastUserIds: [],
        }
    },
    computed: {
        userId(){
            return this.$route.query.userId;
        },
        formType(){     //  代表审批拒绝重提
            return this.$route.query.formType;
        },
        flowId(){
            return this.$route.query.flowId;
        },
        hasAction(){
            // return this.formData && (this.formData.status === 'BE_ON_THE_JOB' || this.formData.status === 'CALL_OUT');
            return this.formData && (this.formData.status === 'BE_ON_THE_JOB');
        },
        menuList(){
            if(this.$getCurrentLib() === 'cadre'){
                return [
                    {
                        label: '成长档案',
                        value: 'annex'
                    },
                    {
                        label: '变动记录',
                        value: 'history'
                    },
                ]
            }else if(this.$getCurrentLib() === 'dean'){
                return [
                    {
                        label: '培养档案',
                        value: 'annex'
                    },
                    {
                        label: '变动记录',
                        value: 'history'
                    },
                ]
            }else{
                return [
                    {
                        label: '基础信息',
                        value: 'baseInfo'
                    },
                    {
                        label: '附件材料',
                        value: 'annex'
                    },
                    {
                        label: '变动记录',
                        value: 'history'
                    },
                ]
            }
        }
    },
    async created(){
        this.$store.commit('status/SET_LIST');
        this.pageType = this.menuList[0].value;
        this.formData = await userDetail({ id: this.userId })

		const name = `hrms${this.$getCurrentLib() ? '_' : ''}${this.$getCurrentLib()}`
		const { contrastUserIds = [] } = await getContrastList({ name })
		this.contrastUserIds = contrastUserIds

        if(this.formType){
            this.createType = this.formType;
            getFormData({ id: this.flowId }).then(res => {          //  代表审批拒绝重提
                // console.log(res);
                if(this.createType === 'CHANGE'){
                    this.detailInfoForm = res.extra.formData;
                    this.handleCreate(this.createType,this.detailInfoForm);
                }else{
                    this.changeForm = res.extra.formData;
                    this.handleCreate(this.createType,this.changeForm);
                }
            })
        }
    },
    methods: {
        back(){
            if(this.formType){
                this.$router.push({ path: 'need-handle' });
            }else{
                // console.log(this.$router);
                // console.log(this.$router.history);
                // this.$router.back();
                this.$router.push({
					path: '/person',
					query: {
						...this.$route.query
					}
				});
            }
        },
		// 后端字段为枚举类型，不能为''
    	checkEducationInfoList(formData) {
      		education.forEach((item) => {
        		if (item.require && !formData.educationInfoList[0][item.value]) {
          			this.$set(formData.educationInfoList[0], item.value, null);
        		}
      		});
    	},
        handleCreate(type,formData){
			if(type !== 'EDIT') {
				this.createType = type;
			}
            let changeForm = JSON.parse(JSON.stringify(this.changeForm));
            Object.keys(changeForm).forEach(key => changeForm[key] = ''); 
			const flowTypeMap = {
        		CHANGE: "变更申请",
        		ADJUST_POST: "调职申请",
        		LEAVE: "离退申请",
				EDIT: "编辑"
      		}
            switch(type){
				case 'EDIT':
					this.updateForm(formData || this.formData,type,flowTypeMap[type],(formData,success) => {
						// this.checkEducationInfoList(formData || this.formData)
						const note = `对${formData.name || this.formData.name}进行${flowTypeMap[type]}`
						const path = '/hrms/user/update'
						this.$store.commit('status/SET_LOG', {path, note})
                        updateFlowUser(formData).then(() => {
                            this.$message.success('操作成功');
							userDetail({ id: this.userId }).then(res => {
								this.formData = res;
								this.formData.EDIT_OPERATION = true
								success();
							})
                        })
                    })
					break;
                case 'CHANGE':      //  不同的信息，一个是用户信息，一个是重提信息
                    this.updateForm(formData || this.formData,type,flowTypeMap[type],(formData,success) => {
						// this.checkEducationInfoList(formData || this.formData)
						const note = `对${formData.name || this.formData.name}进行${flowTypeMap[type]}`
						const path = '/hrms/user/change/change-create'
						this.$store.commit('status/SET_LOG', {path, note})
                        createFlowUser(formData).then(res => {
                            this.$message.success('操作成功');
                            success();
                        })
                    })
                	break;
                case 'ADJUST_POST':
                    if(formData){       //  有formData表示为重提
                        changeForm = formData;
                    }else{
                        changeForm.name = this.formData.name;
                        changeForm.originalOrgId = this.formData.positionInfo.orgId;
                        changeForm.originalPositionIds = this.formData.positionInfo.currentPositionIds;
                        changeForm.idCard = this.formData.idCard;
                        changeForm.formType = type;
                    }

                    this.updateForm(changeForm,type,'调职申请',(formData,success) => {
						const note = `对${changeForm.name}进行${flowTypeMap[type]}`
						const path = '/hrms/user/change/leave-create'
						this.$store.commit('status/SET_LOG', {path, note})
                        createJobChangeUser(formData).then(res => {
                            this.$message.success('操作成功');
                            success();
                        })
                    })
                	break;
                case 'LEAVE':
                    if(formData){       //  有formData表示为重提
                        changeForm = formData;
                    }else{
                        changeForm.name = this.formData.name;
                        changeForm.idCard = this.formData.idCard;
                        changeForm.currentOrgId = this.formData.positionInfo.orgId;
                        changeForm.currentPositionIds = this.formData.positionInfo.currentPositionIds;
                        changeForm.formType = type;
                    }

                    this.updateForm(changeForm,type,'离退申请',(formData,success) => {
						const note = `对${changeForm.name}进行${flowTypeMap[type]}`
						const path = '/hrms/user/change/leave-create'
						this.$store.commit('status/SET_LOG', {path, note})
                        createJobChangeUser(formData).then(res => {
                            this.$message.success('操作成功');
                            success();
                        })
                    })
                	break;
            }
        },
        addContrast(){
			 if(this.contrastUserIds.length < 4){
                const exist = this.contrastUserIds.find(id => id === this.userId);
                if(exist){
                    this.$message.error('该干部已经加入对比，无需重复加入');
                }else{
                  	this.contrastUserIds.push(this.userId)
					const name = `hrms${this.$getCurrentLib() ? '_' : ''}${this.$getCurrentLib()}`
					setContrastList({
						name,
						contrastUserIds: this.contrastUserIds
					})
                }
            }else{
                this.$message.error('干部对比人数已满，无法继续加入');
            }
        },
        openContrastClick(){
            this.$router.push({
                path: '/contrast'
            })
        },
    },
    mounted(){},
}
</script>

<style lang="less" scoped>
.user-detail{
    width: 100vw;
    height: 100vh;
    background-color: #EFF3F7;
    .topbar-btn-group{
        
        button{
            padding: 0 8px;
            border-color: #008B61;
            background-color: #fff;
            color: #008B61;
            i{
                font-size: 14px;
                margin-right: 6px;
            }
        }
    }
    .body{
        width: 100%;
        height: calc(100% - 50px);
        padding-top: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        position: relative;
        .content{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            > div{
                display: flex;
                justify-content: center;
                width: 100%;
                height: 100%;
            }
        }
        .btn-group{
            position: absolute;
            bottom: 100px;
            right: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .btn{
                margin-left: 0;
                margin-top: 20px;
                flex-direction: column;
                padding: 10px 5px;
                height: auto;
                i{
                    margin-bottom: 6px;
                }
                position: relative;
            }
            .prompt{
                position: absolute;
                width: 20px;
                height: 20px;
                line-height: 20px;
                color: #fff;
                background-color: #FF5A5A;
                border-radius: 50%;
                right: -10px;
                top: -10px;
                margin: 0;
            }
        }
    }
}
</style>