<template>
    <div class="history">
        <div class="content">
            <ul class="history-list">
                <li class="history-item" v-for="(item, index) in tableData" :key="index">
                    <div class="time">
                        <div>{{ Date.dateFormat(item.updateTime, 'YY-mm-dd') }}</div>
                        <div>{{ `【${EnumType.applyStatus[item.type]}】` }}</div>
                    </div>
                    <div class="info" @click="goDetail(item)">
                        <div class="text">
                            <div class="text-l">
                                <span>{{ EnumType.applyStatus[item.type] }}</span>
                                <i class="iconfont icon-jiantou-you "></i>
                            </div>
                            <span>{{ item.creatorName }} / {{ item.createTime }}</span>
                        </div>
                        <div class="org">
                            <span>{{$replaceText('现任部门')}}：</span>
                            <span>{{ item.orgName }}</span>
                        </div>
                        <div class="org">
                            <span>现任职务：</span>
                            <span>{{ item.currentPositionNames && item.currentPositionNames.split(';').join('；') }}</span>
                        </div>
                        <div class="point"></div>
                    </div>
                </li>
                <div class="line"></div>
            </ul>
        </div>

        <el-dialog :visible.sync="open" width="80%" v-if="detailInfo" :close-on-click-modal="false">
            <template slot="title">
                <FlowHeader :detailInfo="detailInfo"></FlowHeader>
            </template>
            <div class="detail-container">
                <Detail :detailInfo="detailInfo.extra.formData"
                    v-if="detailInfo.type === 'REGISTER' || detailInfo.type === 'CHANGE'"></Detail>
                <!-- ADJUST_POST(调职) -->
                <Transfer v-if="detailInfo.type === 'ADJUST_POST'" :title="detailInfo.name"
                    :form="detailInfo.extra.formData" :detailInfo="detailInfo"></Transfer>
                <!-- LEAVE(离退) -->
                <Leave v-if="detailInfo.type === 'LEAVE'" :title="detailInfo.name" :form="detailInfo.extra.formData"
                    :detailInfo="detailInfo"></Leave>

                <Examine class="mt15" :tableData="detailInfo.historyWorkFlows"></Examine>


            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getFormData } from '@/api/hrAPI.js';
import { changeHistory } from '@/api/hrAPI.js';
// import ExamineHeader from './examineHeader';
import Detail from '@/components/detail';
import Transfer from '@/components/table/transfer';
import Leave from '@/components/table/leave';
import Examine from '@/components/table/examine';
import FlowHeader from '@/components/flowHeader';

export default {
    components: {
        // ExamineHeader,
        Detail,
        Transfer,
        Leave,
        Examine,
        FlowHeader
    },
    props: {
        userId: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            tableData: [],
            open: false,
            detailInfo: null,
        }
    },
    created () {
        changeHistory({ id: this.userId }).then(res => {
            this.tableData = res;

        })
    },
    methods: {
        goDetail (row) {
            getFormData({ id: row.id }).then(res => {
                this.open = true;
                this.detailInfo = res;

            })
        }
    }
}
</script>

<style lang="less" scoped>
.history {
    .content {
        width: 50%;
        height: 100%;
        overflow: auto;
        background-color: #fff;
        padding: 30px 40px;
        box-sizing: border-box;

        .history-list {
            position: relative;

            .line {
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: #E6E6E6;
                top: 20px;
                left: 140px;
            }

            .history-item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 40px;

                .time {
                    width: 180px;

                    >div:first-child {
                        font-size: 20px;
                    }

                    >div:last-child {
                        margin-top: 15px;
                        color: #008B61;
                    }
                }

                .info {
                    width: 70%;
                    padding: 20px 17px;
                    box-sizing: border-box;
                    border: 1px dashed #bbb;
                    position: relative;
                    cursor: pointer;

                    .text {
                        height: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        line-height: 22px;
                        span{
                                line-height: 22px;
                            }
                        .text-l {

                            font-size: 20px;
                            margin-right: 18px;
                            font-weight: bold;
                            i {
                                border: 1px solid #666;
                                border-radius: 50%;
                                // padding: 1px;
                                margin-left: 20px;
                            }

                        }

                        // >span {

                        // }


                    }

                    .org {
                        margin-top: 16px;
                    }

                    .point {
                        position: absolute;
                        left: -48px;
                        top: 10px;
                        width: 14px;
                        height: 14px;
                        background-color: #008B61;
                        border-radius: 50%;
                        z-index: 1;
                    }
                }

                .info:hover {
                    background-color: #f5f7fa;
                }
            }
        }
    }
}

.detail-container {
    height: 560px;
    overflow: auto;
}</style>