<template>
    <div class="annex">
        <div class="sidebar">
            <div class="top">
                <div class="title">{{title}}</div>
                <button class="download iconfont icon-xiazai-01" @click="downloadAll" v-permission="['/hrms/user/download-zip']"></button>
            </div>
            <ul class="file-list">
                <li class="file-item" v-for="(item, index) in annexHeaders" :key="index">
                    <div class="dir" @click="item.expand = !item.expand">
                        <i class="iconfont icon-xiala1" :class="{ 'expand': item.expand }" v-if="item.children.length"></i>
                        <img src="@/assets/dir.png" alt="">
                        <span :title="item.label">{{ item.label }}</span>
                    </div>
                    <div class="children" v-show="item.expand">
                        <div class="file" :class="{ 'active': activePath === file.filePath }" v-for="file in item.children"
                            :key="file.filePath" @click="fileClick(file)">
                            <i class="iconfont " :class="file.icon"></i>
                             <!-- <i class="iconfont icon-docx" ></i> -->
                            <span :title="file.fileName">{{ file.fileName }}</span>
                            <button class="download iconfont icon-export" @click.stop="download(file)"></button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="preview">
            <FileViewer :filePath="activePath"></FileViewer>
        </div>
    </div>
</template>

<script>
import { Base64 } from 'js-base64';

export default {
    props: {
        fileList: {
            type: Array,
            default () {
                return [];
            }
        },
        userId: String
    },
    data () {
        return {
            fileTypeMap: {
                'pdf': 'icon-pdf',
                'jpg': 'icon-jpg',
                'png': 'icon-png',
                'xml': 'icon-xml',
                'xls': 'icon-xls',
                'wps': 'icon-wps',
                'tif': 'icon-tif',
                'txt': 'icon-txt',
                'doc': 'icon-doc',
                'xlsx':'icon-xls',
                'pptx':'icon-ppt',
                'docx':'icon-doc',
                'ppt':'icon-ppt',
                'mp4':'icon-mp3',
                'mp3':'icon-mp3',
                'tiff':'icon-tif',
                'avi':'icon-qita',
                'et':'icon-qita',
                'gif':'icon-qita',
                'dpt':'icon-qita',
            },
            activePath: '',
            annexHeaders: [],

        }
    },
    computed: {
        hrmsDir(){
            const path = this.$route.path;
            if(path.endsWith('/cadre')){
                return this.$store.getters.dictMap['hrms_dir_cadre'];
            }else if(path.endsWith('/dean')){
                return this.$store.getters.dictMap['hrms_dir_dean'];
            }else{
                return this.$store.getters.dictMap['hrms_dir'];
            }
        },
        title(){
            const path = this.$route.path;
            if(path.endsWith('/cadre')){
                return '成长档案';
                // return '年轻干部信息库成长档案目录';
            }else if(path.endsWith('/dean')){
                return '培养档案';
                // return '院长梯队库目录培养档案目录';
            }else{
                return '附件资料';
            }
        }
    },
    created () {
        // console.log(this.hrmsDir);
        let flag = true;
        this.annexHeaders = this.hrmsDir.map(item => {
            return {
                label: item.label,
                value: item.id,
                expand: true,
                children: (this.fileList || []).filter(file => file.dirName === item.label).map(file => {
                    // console.log(file,'file');
                    if(flag){
                        this.fileClick(file);       //  默认显示第一个文件
                        flag = false;
                    }
                    return {
                        ...file,
                        icon: this.fileTypeMap[file.fileType]
                    }
                }),

            }
        })
    },
    methods: {
        fileClick (data) {
            // console.log(data,'f');
            this.activePath = data.filePath;

        },
        downloadAll () {        //  下载全部文件
            // console.log(router.currentRoute.path);
			if(!this.fileList.length) {
				this.$message.error('没有资料，无法下载！！！')
				return 
			}

            const token = this.$store.getters.token;
            let href = `/api_v2/hrms/user/download-zip?id=${this.userId}&WHRP_TOKEN=${token}`;
            let addPath;
            if(this.$route.path.endsWith('/cadre')){
                addPath = '/cadre';
            }
            if(this.$route.path.endsWith('/dean')){
                addPath = '/dean';
            }
            
            if(addPath){
                href = href.replace('/hrms',`/hrms${addPath}`);
            }
            // console.log(href);
            const a = document.createElement('a');
            a.href = href;
            a.target = "_blank";
            a.click();
        },
        download (file) {
            console.log(file,'----------');
            const a = document.createElement('a');
            a.href = file.filePath.replace(/(\w+):\/\/[^/:]+(:\d*)?/, '/api_v2');
            a.download = file.fileName;
            a.click();
        },
    },
    mounted () {

    },
}
</script>

<style lang="less" scoped>
.annex {
    position: relative;
    width: 100%;
    justify-content: flex-start !important;

    .sidebar {
        width: 350px;
        height: 100%;
        background-color: #fff;
        // position: absolute;
        // left: 0;
        padding: 20px;
        box-sizing: border-box;

        .top {
            position: relative;
            display: flex;
            justify-content: space-between;

            .title {
                color: #999;
            }

            .download {
                color: #666;
                cursor: pointer;
            }
        }

        .file-list {
            margin-top: 20px;

            .file-item {
                .dir {
                    min-height: 26px;

                    i {
                        color: #666;
                        transform: rotate(-90deg);
                    }

                    img {
                        margin: 0 6px;
                    }

                    i.expand {
                        transform: rotate(0);
                    }
                }

                .file {
                    height: 34px;
                    padding-left: 44px;
                    cursor: pointer;

                    i {
                        margin-right: 6px;
                    }

                    .download {
                        margin-left: auto;
                    }
                }

                .dir,
                .file {
                    display: flex;
                    align-items: center;

                    >span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .dir:hover,
                .file:hover {
                    background-color: #f5f7fa;
                    cursor: pointer;
                }

                .file.active {
                    background-color: rgba(0, 255, 178, 0.15);
                }
            }
        }
    }

    .preview {
        margin-left: 100px;
        width: 60%;
        height: 100%;
        border: 1px solid #ccc;
        box-sizing: border-box;
        background-color: #fff;
        display: flex;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}</style>