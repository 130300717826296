<template>
    <div class="base-info">
        <div class="table-container" @scroll="scroll" ref="tableContainer">
            <Detail :detailInfo="formData" v-if="formData">
            </Detail>
        </div>
        <div class="rightbar">
            <div class="rightbar-item" :class="{'active': scrollIndex === index}" v-for="(item,index) in rightbarList" :key="index" @click="scrollClick(item)">{{item.label}}</div>
        </div>
    </div>
</template>

<script>
import Detail from '@/components/detail';

export default {
    components: {
        Detail
    },
    props: {
        formData: Object
    },
    data(){
        return {
            rightbarList: [
                {
                    label: '基本信息',
                    value: 0
                },
                {
                    label: '教育信息',
                    value: 430
                },
                {
                    label: '职务信息',
                    value: 590
                },
                {
                    label: '简历',
                    value: 846
                },
                {
                    label: '考核情况',
                    value: 1006
                },
                {
                    label: '家庭情况',
                    value: 1214
                },
                // {
                //     label: '呈报信息',
                //     value: 1614
                // },
            ],
            scrollTop: 0,
            scrollIndex: 0,
        }
    },
    watch: {
        scrollTop: {
            handler(val){
                const index = this.rightbarList.findIndex(item => (item.value + 20) > val);     //  20代表padding:20px
                this.scrollIndex = index;
            }
        }
    },
    methods: {
        scroll(event){
            this.scrollTop = event.target.scrollTop;

        },
        scrollClick(item){
            this.$refs.tableContainer.scrollTop = item.value - 20;
        }
        
    },
    mounted(){

	},
}
</script>

<style lang="less" scoped>

.base-info{
    
    .table-container{
        height: 100%;
        width: 60%;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;
        overflow: auto;
    }
    .rightbar{
        position: absolute;
        width: 120px;
        height: calc(100% - 2px);
        background-color: #fff;
        top: 1px;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .rightbar-item{
            margin-top: 16px;
            cursor: pointer;
        }
        .rightbar-item.active{
            color: #008B61;
        }
    }
}
</style>